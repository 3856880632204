<template>
  <app-overlay>
    <app-detail-table
      v-if="item"
      :data="item"
      :fields="fields"
    >
      <template #status="item">
        <b-badge
          v-if="item && item.status"
          :variant="$variants[item.status]"
        >
          {{ $capitalize(item.status) }}
        </b-badge>
      </template>
    </app-detail-table>
    <hr class="mt-0">
    <b-row v-if="item && item.status === 'arandı'">
      <b-col md="6">
        <validation-observer ref="statusForm">
          <app-select-input
            v-model="status"
            name="Durum"
            placeholder="Durum Seçiniz..."
            :options="statusOptions"
            no-gap
            rules="required"
          />
        </validation-observer>
      </b-col>
      <b-col md="6">
        <app-button
          size="md"
          text="Güncelle"
          icon="RefreshCwIcon"
          @click="updateStatus"
        />
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col md="6">
        <app-button
          variant="gradient-success"
          class="ml-50"
          text="Ara"
          icon="PhoneIcon"
          block
          @click="call"
        />
      </b-col>
    </b-row>
    <hr>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  components: { ValidationObserver },
  data() {
    return {
      status: null,
      statusOptions: ['ulaşılamadı', 'meşgul', 'not bırakıldı', 'teklif bekliyor', 'tekrar aranacak'],
      fields: [
        { key: 'phone', label: 'Telefon' },
        { key: 'createdAt', label: 'Oluşturulma Tarihi', formatDateTime: true },
        { key: 'whenCalled', label: 'Aranma Tarihi', formatDateTime: true },
      ],
    }
  },
  computed: {
    item() {
      return this.$store.getters.appModalItem
    },
  },
  mounted() {
    this.$socket.on('WEB_CALL_REQUEST_UPDATED', data => {
      this.$store.commit('updateWebCallRequest', data)
      this.$store.commit('updateRequestHistory', data)
      if (this.item?._id === data?._id) {
        this.$store.commit('setAppModalOptions', { item: data })
      }
    })
  },
  destroyed() {
    this.$socket.off('WEB_CALL_REQUEST_UPDATED')
  },
  methods: {
    call() {
      this.$axios
        .post(`/central/web-call-request/${this.item?._id}/call`)
        .then(result => {
          this.$emitter.$emit('Notification', {
            variant: 'success',
            title: 'İşlem Başarılı',
            message: result.data.message,
          })
        })
        .catch(err => {
          this.$emitter.$emit('Notification', {
            variant: 'danger',
            title: 'İşlem Başarısız',
            message: err.response.data.message,
          })
          console.log(err)
        })
    },
    updateStatus() {
      this.$validate(this.$refs.statusForm, () => {
        this.$axios
          .patch(`/central/web-call-request/${this.item?._id}/update-status`, { status: this.status })
          .then(() => {
            this.status = null
            this.$refs?.statusForm?.reset()
            this.$store.commit('cleanAppModal')
            this.$emitter.$emit('Notification', {
              variant: 'success',
              title: 'İşlem Başarılı',
              message: 'Durum güncellendi.',
            })
          })
          .catch(err => {
            this.$emitter.$emit('Notification', {
              variant: 'danger',
              title: 'Hata Oluştu',
            })
            console.log(err)
          })
      })
    },
  },
}
</script>

<style></style>
